
import caarcservebackupkm from 'data/releases/ca-arcserve-backup-km-releases.json';
import cephcapacityviews from 'data/releases/ceph-capacity-views-releases.json';
import dellemcelasticcloudstorageetl from 'data/releases/dell-emc-elastic-cloud-storage-etl-releases.json';
import dellemcunitycimserveretl from 'data/releases/dell-emc-unity-cim-server-etl-releases.json';
import dellemcunitykm from 'data/releases/dell-emc-unity-km-releases.json';
import emcdiskarrayskm from 'data/releases/emc-disk-arrays-km-releases.json';
import emcnetworkerkm from 'data/releases/emc-networker-km-releases.json';
import emcsmisagentetl from 'data/releases/emc-smis-agent-etl-releases.json';
import emcxtremiomanagementserveretl from 'data/releases/emc-xtremio-management-server-etl-releases.json';
import emcxtremiokm from 'data/releases/emc-xtremio-km-releases.json';
import hardwareconnectors from 'data/releases/hardware-connectors-releases.json';
import hardwaresentry from 'data/releases/hardware-sentry-releases.json';
import hardwaresentrydashboardhelix from 'data/releases/hardware-sentry-dashboard-helix-releases.json';
import hardwaresentrykm from 'data/releases/hardware-sentry-km-releases.json';
import hitachidevicemanageretl from 'data/releases/hitachi-device-manager-etl-releases.json';
import hitachidiskarrayskm from 'data/releases/hitachi-disk-arrays-km-releases.json';
import hp3paretl from 'data/releases/hp-3par-etl-releases.json';
import hp3parkm from 'data/releases/hp-3par-km-releases.json';
import hpdataprotectorkm from 'data/releases/hp-data-protector-km-releases.json';
import hpevakm from 'data/releases/hp-eva-km-releases.json';
import ibmds3000ds4000ds5000serieskm from 'data/releases/ibm-ds3000-ds4000-ds5000-series-km-releases.json';
import ibmds6000ds8000serieskm from 'data/releases/ibm-ds6000-ds8000-series-km-releases.json';
import ibmspectrumprotectkm from 'data/releases/ibm-spectrum-protect-km-releases.json';
import ibmsvcstorwizecimagentetlfortruesight from 'data/releases/ibm-svc-storwize-cim-agent-etl-releases.json';
import ibmsvcstorwizekm from 'data/releases/ibm-svc-storwize-km-releases.json';
import ibmxivetl from 'data/releases/ibm-xiv-etl-releases.json';
import ibmxivkm from 'data/releases/ibm-xiv-km-releases.json';
import monitoringstudiokm from 'data/releases/monitoring-studio-km-releases.json';
import monitoringstudiox from 'data/releases/monitoring-studio-x-releases.json';
import netappcapacityviews from 'data/releases/netapp-capacity-views-releases.json';
import netappdataontapetl from 'data/releases/netapp-data-ontap-etl-releases.json';
import netappfilerskm from 'data/releases/netapp-filers-km-releases.json';
import purestoragekm from 'data/releases/pure-storage-km-releases.json';
import purestoragerestapietl from 'data/releases/pure-storage-rest-api-etl-releases.json';
import storageallinoneetl from 'data/releases/storage-all-in-one-etl-releases.json';
import storageanalyzerkm from 'data/releases/storage-analyzer-km-releases.json';
import storagecapacityviewsandreports from 'data/releases/storage-capacity-views-and-reports-releases.json';
import veritasclusterserverkm from 'data/releases/veritas-cluster-server-km-releases.json';
import veritasfilesystemkm from 'data/releases/veritas-file-system-km-releases.json';
import veritasnetbackupkm from 'data/releases/veritas-netbackup-km-releases.json';
import veritasvolumemanagerkm from 'data/releases/veritas-volume-manager-km-releases.json';

/**
* All releases as a JSON node
*/
const jsonMappings: Record<string, any> = {
    'ca-arcserve-backup-km': {
        name: 'CA ArcServe Backup KM',
        releases: caarcservebackupkm,
        latestVersion: '1.5.00',
        solution: 'Backup Monitoring'
    },
    'ceph-capacity-views': {
        name: 'Ceph Capacity Views',
        releases: cephcapacityviews,
        latestVersion: 'Code Level 148',
        solution: 'Storage Monitoring'
    },
    'dell-emc-elastic-cloud-storage-etl': {
        name: 'Dell EMC Elastic Cloud Storage ETL',
        releases: dellemcelasticcloudstorageetl,
        latestVersion: 'Code Level 154',
        solution: 'Storage Capacity Optimization'
    },
    'dell-emc-unity-cim-server-etl': {
        name: 'Dell EMC Unity CIM Server ETL',
        releases: dellemcunitycimserveretl,
        latestVersion: 'Code Level 144',
        solution: 'Storage Capacity Optimization'
    },
    'dell-emc-unity-km': {
        name: 'Dell EMC Unity KM',
        releases: dellemcunitykm,
        latestVersion: '1.0.02',
        solution: 'Storage Monitoring'
    },
    'emc-disk-arrays-km': {
        name: 'EMC Disk Arrays KM',
        releases: emcdiskarrayskm,
        latestVersion: '4.2.00',
        solution: 'Storage Monitoring'
    },
    'emc-networker-km': {
        name: 'EMC Networker KM',
        releases: emcnetworkerkm,
        latestVersion: '4.0.03',
        solution: 'Backup Monitoring'
    },
    'emc-smis-agent-etl': {
        name: 'EMC SMI-S Agent ETL',
        releases: emcsmisagentetl,
        latestVersion: 'Code Level 144',
        solution: 'Storage Capacity Optimization'
    },
    'emc-xtremio-management-server-etl': {
        name: 'EMC Xtremio Management Server ETL',
        releases: emcxtremiomanagementserveretl,
        latestVersion: '1.2.01',
        solution: 'Storage Capacity Optimization'
    },
    'emc-xtremio-km': {
        name: 'EMC Xtremio KM',
        releases: emcxtremiokm,
        latestVersion: 'Code Level 153',
        solution: 'Storage Monitoring'
    },
    'hardware-connectors': {
        name: 'Hardware Connectors',
        releases: hardwareconnectors,
        latestVersion: '38',
        solution: 'Hardware Monitoring'
    },
    'hardware-sentry': {
        name: 'Hardware Sentry',
        releases: hardwaresentry,
        latestVersion: '2',
        solution: 'Hardware Monitoring'
    },
    'hardware-sentry-dashboard-helix': {
        name: 'Hardware Sentry Dashboard Helix',
        releases: hardwaresentrydashboardhelix,
        latestVersion: '3.1.02',
        solution: 'Hardware Monitoring'
    },
    'hardware-sentry-km': {
        name: 'Hardware Sentry KM',
        releases: hardwaresentrykm,
        latestVersion: '11.4.02',
        solution: 'Hardware Monitoring'
    },
    'hitachi-device-manager-etl': {
        name: 'Hitachi Device Manager ETL',
        releases: hitachidevicemanageretl,
        latestVersion: 'Code Level 148',
        solution: 'Storage Capacity Optimization'
    },
    'hitachi-disk-arrays-km': {
        name: 'Hitachi Disk Arrays KM',
        releases: hitachidiskarrayskm,
        latestVersion: '1.5.00',
        solution: 'Storage Monitoring'
    },
    'hp-3par-etl': {
        name: 'HP 3PAR ETL',
        releases: hp3paretl,
        latestVersion: 'Code Level 155',
        solution: 'Storage Capacity Optimization'
    },
    'hp-3par-km': {
        name: 'HP 3PAR KM',
        releases: hp3parkm,
        latestVersion: '1.2.02',
        solution: 'Storage Monitoring'
    },
    'hp-data-protector-km': {
        name: 'HP Data Protector KM',
        releases: hpdataprotectorkm,
        latestVersion: '2.1.00',
        solution: 'Backup Monitoring'
    },
    'hp-eva-km': {
        name: 'HP EVA KM',
        releases: hpevakm,
        latestVersion: '1.3.00',
        solution: 'Storage Monitoring'
    },
    'ibm-ds3000-ds4000-ds5000-series-km': {
        name: 'IBM DS3000 DS4000 DS5000 series KM',
        releases: ibmds3000ds4000ds5000serieskm,
        latestVersion: '1.2.00',
        solution: 'Storage Monitoring'
    },
    'ibm-ds6000-ds8000-series-km': {
        name: 'IBM DS6000 DS8000 series KM',
        releases: ibmds6000ds8000serieskm,
        latestVersion: '1.3.01',
        solution: 'Storage Monitoring'
    },
    'ibm-spectrum-protect-km': {
        name: 'IBM Spectrum protect KM',
        releases: ibmspectrumprotectkm,
        latestVersion: '4.0.05',
        solution: 'Backup Monitoring'
    },
    'ibm-svc-storwize-cim-agent-etl': {
        name: 'IBM Svc storwize cim Agent ETL',
        releases: ibmsvcstorwizecimagentetlfortruesight,
        latestVersion: 'Code Level 153',
        solution: 'Storage Capacity Optimization'
    },
    'ibm-svc-storwize-km': {
        name: 'IBM Svc storwize KM',
        releases: ibmsvcstorwizekm,
        latestVersion: '2.2.00',
        solution: 'Storage Monitoring'
    },
    'ibm-xiv-etl': {
        name: 'IBM XIV ETL',
        releases: ibmxivetl,
        latestVersion: 'Code Level 144',
        solution: 'Storage Capacity Optimization'
    },
    'ibm-xiv-km': {
        name: 'IBM XIV KM',
        releases: ibmxivkm,
        latestVersion: '1.1.00',
        solution: 'Storage Monitoring'
    },
    'monitoring-studio-km': {
        name: 'Monitoring Studio KM',
        releases: monitoringstudiokm,
        latestVersion: '9.4.03',
        solution: 'Application Monitoring'
    },
    'monitoring-studio-x': {
        name: 'Monitoring Studio X',
        releases: monitoringstudiox,
        latestVersion: '10.4.01',
        solution: 'Application Monitoring'
    },
    'netapp-capacity-views': {
        name: 'NetApp Capacity Views',
        releases: netappcapacityviews,
        latestVersion: 'Code Level 154',
        solution: 'Storage Monitoring'
    },
    'netapp-data-ontap-etl': {
        name: 'NetApp Data ontap ETL',
        releases: netappdataontapetl,
        latestVersion: 'Code Level 154',
        solution: 'Storage Capacity Optimization'
    },
    'netapp-filers-km': {
        name: 'NetApp Filers KM',
        releases: netappfilerskm,
        latestVersion: '3.3.08',
        solution: 'Storage Monitoring'
    },
    'pure-storage-km': {
        name: 'Pure Storage KM',
        releases: purestoragekm,
        latestVersion: '1.1.01',
        solution: 'Storage Monitoring'
    },
    'pure-storage-rest-api-etl': {
        name: 'Pure Storage REST API ETL',
        releases: purestoragerestapietl,
        latestVersion: 'Code Level 146',
        solution: 'Storage Capacity Optimization'
    },
    'storage-all-in-one-etl': {
        name: 'Storage All in One ETL',
        releases: storageallinoneetl,
        latestVersion: '1.28.00',
        solution: 'Storage Capacity Optimization'
    },
    'storage-analyzer-km': {
        name: 'Storage Analyzer KM',
        releases: storageanalyzerkm,
        latestVersion: '1.10.00',
        solution: 'Storage Monitoring'
    },
    'storage-capacity-views-and-reports': {
        name: 'Storage Capacity Views and reports',
        releases: storagecapacityviewsandreports,
        latestVersion: 'Code Level 148',
        solution: 'Storage Monitoring'
    },
    'veritas-cluster-server-km': {
        name: 'Veritas Cluster Server KM',
        releases: veritasclusterserverkm,
        latestVersion: '3.1.01',
        solution: 'High-Availability Monitoring'
    },
    'veritas-file-system-km': {
        name: 'Veritas File System KM',
        releases: veritasfilesystemkm,
        latestVersion: '3.1.01',
        solution: 'High-Availability Monitoring'
    },
    'veritas-netbackup-km': {
        name: 'Veritas NetBackup KM',
        releases: veritasnetbackupkm,
        latestVersion: '4.0.03',
        solution: 'Backup Monitoring'
    },
    'veritas-volume-manager-km': {
        name: 'Veritas Volume Manager KM',
        releases: veritasvolumemanagerkm,
        latestVersion: '3.1.01',
        solution: 'High-Availability Monitoring'
    }
};

// Step 1: Merge all entries into a single table
export const allProductReleases: Array<any> = Object.values(jsonMappings).flatMap(node => node.releases.map((release: any) => ({...release, solution: node.solution, name: node.name, latestVersion: node.latestVersion}))).sort((a, b) => {
    // Step 2: Sort by releaseDate (descending order) then by productId (ascending order)
    const dateA = new Date(a.releaseDate);
    const dateB = new Date(b.releaseDate);

    // Sort by releaseDate (descending order)
    if (dateA > dateB) return -1;
    if (dateA < dateB) return 1;

    // Sort by productId (ascending order)
    if (a.productId < b.productId) return -1;
    if (a.productId > b.productId) return 1;

    return 0; // If everything is equal
});
